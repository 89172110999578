<script setup>
import { homepageTransition } from '~/helpers/animations/animations';

const route = useRoute();
const { t } = useI18n();
definePageMeta({
  pageTransition: homepageTransition,
});

import homeQuery from '~/assets/gql/home.gql';

const store = useCommonStore();
const { setImages, setItems, setPageColor, setTypeVariant } = store;

const { data } = await useAsyncQuery(homeQuery);

const featuredNews = ref(data.value.featuredNews);
const featured = ref(data.value.featured);

const sidebarImages = ref([]);
const featuredNewsResult = ref([]);

const getImage = (item, key) => {
  if (item.__typename === 'Event') {
    return item.image ? item.image.url : `/img/dummy/ngp.png`;
  } else {
    return item.news_image ? item.news_image : `/img/dummy/ngp.png`;
  }
};

featured.value.items.map((item) => sidebarImages.value.push(getImage(item.item)));
setImages(sidebarImages.value);
setItems(featured.value.items);
setTypeVariant(featured?.value?.items[0]?.item?.logo_variant);

store.setPageColor('white');
onMounted(() => {
  store.setPageColor('white');
});
onBeforeRouteLeave(() => {
  store.setPageColor(null);
});

featuredNews.value.forEach((news) => {
  featuredNewsResult.value.push({
    id: news.news_id,
    name: {
      cs: news.title_cs,
      en: news.title_en,
    },
    slug: {
      cs: news.url_cs,
      en: news.url_en,
      __typename: news.__typename,
    },
    __typename: 'Page',
  });
});

useHeadData({
  description: t('global.head.description'),
  keywords: t('global.head.keywords'),
  image: null,
  url: route.fullPath,
});

const news = [...featuredNewsResult.value];
</script>

<template>
  <main class="main main--narrow main--white">
    <div ref="home" class="home">
      <div class="home__content-inner">
        <h1 class="hidden">{{ $t('pages.index.hidden-title') }}</h1>
        <NewsBar v-show="news" :news="news" />
        <section>
          <FeaturedItems />
          <nuxt-link :to="localePath('events')" class="button button--featured">
            {{ $t('pages.index.link.all-actions') }}
          </nuxt-link>
        </section>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.home {
  position: relative;
  //overflow: hidden;
  margin-top: -130px;

  @include mq($lg) {
    margin-top: 0;
  }

  &__content {
    height: 100%;
    overflow: auto;
    padding-left: 50%;
  }
}
</style>
