import { useRoute } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', () => {
    const route = useRoute();
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'nuxtRoute',
      page_path: route.fullPath,
      page_title: document.title,
      page_routeName: route.name,
      page_type: 'PageView',
    });
  });
});
