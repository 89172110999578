export default function useHeadData(metadata) {
  const { t, locale } = useI18n();
  useHead({
    htmlAttrs: {
      lang: locale,
    },
    title: metadata.title ? `${metadata.title} | ${t('global.company-full-name')}` : t('global.company-full-name'),
    meta: [
      { name: 'description', content: metadata.description },
      { name: 'keywords', content: metadata.keywords },
      { property: 'og:url', content: 'https://www.ngprague.cz' + metadata.url },
      { property: 'og:title', content: `${metadata.title} | ${t('global.company-full-name')}` },
      { property: 'og:description', content: metadata.description },
      { property: 'og:image', content: metadata.image ? useGetImageUrl(metadata.image, 600) : null },
      { property: 'twitter:url', content: 'https://www.ngprague.cz' + metadata.url },
      { property: 'twitter:title', content: `${metadata.title} | ${t('global.company-full-name')}` },
      { property: 'twitter:description', content: metadata.description },
      { property: 'twitter:image', content: metadata.image ? useGetImageUrl(metadata.image, 600) : null },
    ],
  });
}
